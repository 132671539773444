import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { graphql, StaticQuery } from 'gatsby'
import classNames from 'classnames'
import AnimateHeight from 'react-animate-height'

import styles from './header.module.css'

import Logo from './logo'
import NavMenu from './navMenu'
import NavMenuMobile from './navMenuMobile'

class Header extends Component {
  state = {
    mobileNavActive: false,
  }

  toggleMobileNav() {
    this.setState({
      mobileNavActive: !this.state.mobileNavActive,
    })
  }

  keyDown = event => {
    if (event.keyCode === 27) {
      this.setState({
        mobileNavActive: false,
      })
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false)
  }

  render() {
    const { title, mainMenu, secondaryMenu } = this.props
    return (
      <header
        className={classNames(styles.root, {
          [`${styles.mobileNavActive}`]: this.state.mobileNavActive,
        })}
        role="banner"
        aria-label="Site banner"
      >
        <div className={styles.main}>
          <div className={styles.wrapper}>
            <AniLink
              fade
              to="/"
              className={styles.branding}
              rel="home"
              title={`Top ${title} homepage link`}
            >
              <Logo alt={title} />
            </AniLink>
            <a href="#content" className="visuallyhidden">
              Skip to main content
            </a>
            {mainMenu.length > 0 && (
              <NavMenu
                title="Primary"
                data={mainMenu}
                className={styles.menu}
              />
            )}

            {secondaryMenu.length > 0 && (
              <NavMenu
                title="Secondary"
                data={secondaryMenu}
                className={styles.menu}
              />
            )}
            <button
              aria-expanded={this.state.mobileNavActive ? 'true' : 'false'}
              aria-controls="mobile-menu"
              className={styles.burger}
              onClick={this.toggleMobileNav.bind(this)}
            >
              <span className="visuallyhidden">Open mobile menu</span>
            </button>
          </div>
        </div>
        <AnimateHeight
          duration={500}
          height={this.state.mobileNavActive ? 'auto' : 0}
          className={styles.mobile}
        >
          <NavMenuMobile
            mainMenu={mainMenu}
            secondaryMenu={secondaryMenu}
            isActive={this.state.mobileNavActive}
          />
        </AnimateHeight>
      </header>
    )
  }
}

Header.propTypes = {
  title: PropTypes.string,
  mainMenu: PropTypes.array.isRequired,
  secondaryMenu: PropTypes.array.isRequired,
}

Header.defaultProps = {
  title: '',
}

export default ({ title }) => (
  <StaticQuery
    query={graphql`
      {
        mainMenu: file(relativePath: { eq: "menus/main.json" }) {
          childMenusJson {
            items {
              title
              url
              type
              classes
            }
          }
        }
        secondaryMenu: file(relativePath: { eq: "menus/secondary.json" }) {
          childMenusJson {
            items {
              title
              url
              type
              classes
            }
          }
        }
      }
    `}
    render={data => (
      <Header
        title={title}
        mainMenu={data.mainMenu.childMenusJson.items}
        secondaryMenu={data.secondaryMenu.childMenusJson.items}
      />
    )}
  />
)
