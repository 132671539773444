import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import MarkdownWithHtml from 'react-markdown/with-html'

import styles from './ageDisclaimer.module.css'

import Logo from './logo'

class AgeDisclaimer extends Component {
  constructor(props) {
    super(props)
    this.cookieKey = 'KRIS_age_verified'
    this.disclaimer = React.createRef()
    this.state = {
      accepted: Cookies.get(this.cookieKey),
    }
  }

  acceptDisclaimer() {
    Cookies.set(this.cookieKey, 1)
    this.setState({
      accepted: true,
    })
  }

  componentDidMount() {
    if (!this.state.accepted) {
      // console.log('Focusing on element', this.disclaimer.current)
      // this.disclaimer.current.focus()
    }
  }

  render() {
    const { title, headline, content } = this.props

    if (this.state.accepted) {
      return null
    }

    return (
      <section
        className={styles.root}
        aria-label="Legal Drinking Age Verification Notice"
      >
        <div className={styles.container} ref={this.disclaimer} tabIndex={1}>
          <Logo alt={title} className={styles.logo} />
          <h2 className="title title--small">{headline}</h2>
          <MarkdownWithHtml
            className={styles.content}
            source={content}
            escapeHtml={false}
          />
          <button
            className="button button--inverse"
            onClick={this.acceptDisclaimer.bind(this)}
          >
            Enter Site
          </button>
        </div>
      </section>
    )
  }
}

AgeDisclaimer.propTypes = {
  title: PropTypes.string.isRequired,
  headline: PropTypes.string,
  content: PropTypes.string,
}

AgeDisclaimer.defaultProps = {
  headline: 'Welcome to KRIS Wines!',
  content: '',
}

export default AgeDisclaimer
