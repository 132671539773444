import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import MarkdownWithHtml from 'react-markdown/with-html'
import AnimateHeight from 'react-animate-height'

import styles from './cookieDisclaimer.module.css'

class CookieDisclaimer extends Component {
  constructor(props) {
    super(props)
    this.cookieKey = 'KRIS_accepted_cookies'
    this.disclaimer = React.createRef()
    this.state = {
      accepted: Cookies.get(this.cookieKey),
    }
  }

  acceptDisclaimer() {
    Cookies.set(this.cookieKey, 1)
    this.setState({
      accepted: true,
    })
  }

  componentDidMount() {
    if (!this.state.accepted) {
      this.disclaimer.current.focus()
    }
  }

  render() {
    const { content } = this.props

    return (
      <AnimateHeight
        duration={500}
        height={this.state.accepted ? 0 : 'auto'}
        className={styles.root}
      >
        <section
          aria-label="Cookie Compliance Notice"
          className={styles.container}
          ref={this.disclaimer}
        >
          <div className={styles.wrapper} tabIndex={1}>
            <div className={styles.content}>
              <MarkdownWithHtml source={content} escapeHtml={false} />
            </div>

            <button
              aria-expanded={!this.state.accepted}
              className="button button--small button--inverse"
              onClick={this.acceptDisclaimer.bind(this)}
            >
              Accept Cookies
            </button>
          </div>
        </section>
      </AnimateHeight>
    )
  }
}

CookieDisclaimer.propTypes = {
  content: PropTypes.string,
}

CookieDisclaimer.defaultProps = {
  content: '',
}

export default CookieDisclaimer
