import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const Image = ({ node, alt, className }) => {
  if (!node) {
    return null
  }

  if (node.childImageSharp) {
    if (node.childImageSharp.fluid) {
      return (
        <Img
          fluid={node.childImageSharp.fluid}
          alt={alt}
          className={className}
        />
      )
    }

    if (node.childImageSharp.fixed) {
      return (
        <Img
          fixed={node.childImageSharp.fixed}
          alt={alt}
          className={className}
        />
      )
    }
  }

  if (node.publicURL) {
    return <img src={node.publicURL} alt={alt} className={className} />
  } else {
    return null
  }
}

Image.propTypes = {
  node: PropTypes.object.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
}

Image.defaultProps = {
  alt: '',
}

export default Image
