import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './navMenu.module.css'

import NavLink from './navLink'

const NavMenu = ({ title, data, className }) => (
  <nav className={classNames(styles.root, className)} aria-label={title}>
    <ol className={styles.list}>
      {data.map((item, index) => {
        return (
          <li key={index}>
            <NavLink item={item} className={item.classes} />
          </li>
        )
      })}
    </ol>
  </nav>
)

NavMenu.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
}

export default NavMenu
