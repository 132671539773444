import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { useSiteMetadata } from '../hooks/useSiteMetadata'

function SEO({ lang, meta, title, seoTitle, description }) {
  const {
    title: siteTitle,
    description: siteDescription,
    author: siteAuthor,
  } = useSiteMetadata()

  const metaDescription = description || siteDescription
  const titleTemplate = seoTitle ? '%s' : `%s | ${siteTitle}`
  title = seoTitle ? seoTitle : title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  seoTitle: PropTypes.string,
}

export default SEO
