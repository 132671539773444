import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import '../styles/layout.css'

import { useSiteMetadata } from '../hooks/useSiteMetadata'
import Header from './header'
import Footer from './footer'
import CookieDisclaimer from './cookieDisclaimer'
import AgeDisclaimer from './ageDisclaimer'

const Layout = ({ children }) => {
  const { title, org, ageDisclaimer, cookieDisclaimer } = useSiteMetadata()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('smooth-scroll')('a[href*="#"]', {
        speed: 1000,
      })
    }
  })

  return (
    <>
      <AgeDisclaimer title={title} content={ageDisclaimer} />
      <CookieDisclaimer content={cookieDisclaimer} />
      <Header title={title} />
      <main id="content" aria-label="Site content">
        {children}
      </main>
      <Footer title={title} org={org}></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
