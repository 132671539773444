import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './navMenuMobile.module.css'

import NavLink from './navLink'

class NavMenuMobile extends Component {
  constructor(props) {
    super(props)
    this.menu = React.createRef()
  }

  componentDidUpdate() {
    if (this.props.isActive) {
      // console.log('Focusing on element', this.menu.current)
      this.menu.current.focus()
    } else {
      // console.log('Losing focus on element', this.menu.current)
      this.menu.current.blur()
    }
  }

  render() {
    const { mainMenu, secondaryMenu, className } = this.props

    return (
      <nav
        ref={this.menu}
        id="mobile-menu"
        className={classNames(className, styles.root)}
        aria-label="Mobile"
      >
        <ol className={styles.list}>
          {mainMenu.length > 0 &&
            mainMenu.map((item, index) => (
              <li key={index}>
                <NavLink item={item} className={item.classes} />
              </li>
            ))}

          {secondaryMenu.totalCount &&
            secondaryMenu.map((item, index) => (
              <li key={index}>
                <NavLink item={item} className={item.classes} />
              </li>
            ))}
        </ol>
      </nav>
    )
  }
}

NavMenuMobile.propTypes = {
  mainMenu: PropTypes.array.isRequired,
  secondaryMenu: PropTypes.array.isRequired,
  className: PropTypes.string,
}

NavMenuMobile.defaultProps = {
  isActive: false,
}

export default NavMenuMobile
