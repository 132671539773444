import React from 'react'
import PropTypes from 'prop-types'

import logo from '../images/logo.png'
import logo2x from '../images/logo@2x.png'

const Logo = ({ alt, className }) => (
  <img
    src={logo}
    srcSet={`${logo} 1x, ${logo2x} 2x`}
    alt={alt}
    className={className}
  />
)

Logo.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Logo
