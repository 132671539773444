import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import styles from './footer.module.css'

import ExternalLink from './externalLink'
import Logo from './logo'
import LogoLLS from '../images/logo-lls.inline.svg'
import LinkIcon from './linkIcon'

const Footer = ({ title, org, footerMenu, socialMenu }) => (
  <footer className={styles.root} aria-label="Site footer" role="contentinfo">
    <div className={styles.wrapper}>
      <nav aria-label="Footer" className={styles.nav}>
        <ul className={styles.branding}>
          <li>
            <AniLink
              fade
              to="/"
              className={styles.branding}
              rel="home"
              title={`Top ${title} homepage link`}
            >
              <Logo alt={title} />
            </AniLink>
          </li>
          <li>
            <ExternalLink href="https://www.winebow.com/imports/lls/">
              <LogoLLS />
            </ExternalLink>
          </li>
        </ul>
        {socialMenu.length > 0 && (
          <div>
            <ul className={styles.socialNav}>
              {socialMenu.map((item, index) => (
                <li key={index}>
                  <ExternalLink href={item.url} className={item.classes}>
                    <LinkIcon label={item.title} />
                    <span className="visuallyhidden">{item.title}</span>
                  </ExternalLink>
                </li>
              ))}
            </ul>
          </div>
        )}
        <ul className={styles.footerNav}>
          {footerMenu.length > 0 &&
            footerMenu.map((item, index) => (
              <li key={index}>
                {item.type === 'internal' ? (
                  <AniLink fade to={item.url} className={item.classes}>
                    {item.title}
                  </AniLink>
                ) : (
                  <ExternalLink href={item.url} className={item.classes}>
                    {item.title}
                  </ExternalLink>
                )}
              </li>
            ))}
        </ul>
      </nav>
      <p className={styles.fineprint}>
        &copy; {new Date().getFullYear()}{' '}
        <ExternalLink href={org.url}>{org.name}</ExternalLink> All Rights
        Reserved.
      </p>
    </div>
  </footer>
)

Footer.propTypes = {
  title: PropTypes.string.isRequired,
  org: PropTypes.object.isRequired,
  footerMenu: PropTypes.array.isRequired,
  socialMenu: PropTypes.array.isRequired,
}

export default ({ title, org }) => (
  <StaticQuery
    query={graphql`
      {
        footerMenu: file(relativePath: { eq: "menus/footer.json" }) {
          childMenusJson {
            items {
              classes
              title
              type
              url
            }
          }
        }
        socialMenu: file(relativePath: { eq: "menus/social.json" }) {
          childMenusJson {
            items {
              classes
              title
              type
              url
            }
          }
        }
      }
    `}
    render={data => (
      <Footer
        title={title}
        org={org}
        footerMenu={data.footerMenu.childMenusJson.items}
        socialMenu={data.socialMenu.childMenusJson.items}
      />
    )}
  />
)
