import React from 'react'
import PropTypes from 'prop-types'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import styles from './navLink.module.css'

import ExternalLink from './externalLink'

const NavLink = ({ item, className }) => {
  const linkClass = className ? className : styles.root

  return (
    <>
      {item.type === 'internal' ? (
        <AniLink
          partiallyActive={true}
          activeClassName={className ? null : styles.isActive}
          fade
          to={item.url}
          className={linkClass}
        >
          {item.title}
        </AniLink>
      ) : (
        <ExternalLink href={item.url} className={linkClass}>
          {item.title}
        </ExternalLink>
      )}
    </>
  )
}

NavLink.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default NavLink
