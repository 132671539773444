import React from 'react'
import PropTypes from 'prop-types'

const ExternalLink = ({ href, className, children }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    className={className}
  >
    {children}
  </a>
)

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default ExternalLink
