import React from 'react'
import PropTypes from 'prop-types'

import IconAmazon from '../images/icon-amazon.inline.svg'
import IconBevmo from '../images/icon-bevmo.inline.svg'
import IconTotalWine from '../images/icon-totalwine.inline.svg'
import IconDrizly from '../images/icon-drizly.inline.svg'
import IconWineDotCom from '../images/icon-wine-dot-com.inline.svg'
import IconMinibar from '../images/icon-minibar.inline.svg'

import IconFacebook from '../images/icon-facebook.inline.svg'
import IconTwitter from '../images/icon-twitter.inline.svg'
import IconPinterest from '../images/icon-pinterest.inline.svg'
import IconInstagram from '../images/icon-instagram.inline.svg'
import IconYouTube from '../images/icon-youtube.inline.svg'
import IconEmail from '../images/icon-email.inline.svg'
import IconPrint from '../images/icon-print.inline.svg'

const LinkIcon = ({ label }) => {
  switch (label) {
    case 'Amazon':
      return <IconAmazon />
    case 'Bevmo':
      return <IconBevmo />
    case 'Total Wine':
      return <IconTotalWine />
    case 'Drizly':
      return <IconDrizly />
    case 'Wine.com':
      return <IconWineDotCom />
    case 'Minibar':
      return <IconMinibar />
    case 'Facebook':
      return <IconFacebook />
    case 'Twitter':
      return <IconTwitter />
    case 'Pinterest':
      return <IconPinterest />
    case 'Instagram':
      return <IconInstagram />
    case 'YouTube':
      return <IconYouTube />
    case 'Email':
      return <IconEmail />
    case 'Print':
      return <IconPrint />
    default:
      return null
  }
}

LinkIcon.propTypes = {
  label: PropTypes.string.isRequired,
}

export default LinkIcon
